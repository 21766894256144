<template>
  <el-dialog
      title="位置操作"
      width="70%"
      :visible.sync="states"
      append-to-body
      @close="close">
    <el-row>
      <el-col :span="13"  :offset="1">
        <el-form label-width="80px" :model="form" :rules="rules" ref="form">
          <el-row>
            <el-col :span="11">
              <el-form-item label="婚纱名称">
                <el-input readonly v-model="clothesName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="1">
              <el-form-item label="操作日期" prop="createDate">
                <el-date-picker type="date" placeholder="操作日期"
                                v-model="form.createDate"
                                style="width: 100%;"
                                value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11" >
              <el-form-item label="当前店铺">
                <el-input readonly v-model="clothes.shopName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="1">
              <el-form-item label="当前位置">
                <el-input readonly v-model="clothes.positionName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="操作名称" prop="operationId">
                <el-select v-model="form.operationId" placeholder="操作名称" style="width: 100%">
                  <el-option
                      v-for="item in operationIdArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="1">
              <el-form-item label="经办人" prop="empId">
                <el-select v-model="form.empId" placeholder="经办人" style="width: 100%">
                  <el-option
                      v-for="item in empIdArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="目标店铺" prop="shopId">
                <el-select v-model="form.shopId" placeholder="目标店铺" @change="queryClothesPosition" ref="shop"  style="width: 100%">
                  <el-option
                      v-for="item in shopIdArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="1">
              <el-form-item label="目标位置" prop="positionId" >
                <el-select v-model="form.positionId" placeholder="目标位置" ref="position" style="width: 100%">
                  <el-option
                      v-for="item in clothesPositionArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="23">
              <el-form-item label="操作描述">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 4}"
                    v-model="form.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
        <div style="text-align: right">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="submit">提 交</el-button>
          </div>
      </el-col>
      <el-col :span="9" :offset="1">
        <p style="font-size: 22px;font-weight: bold">位置历史记录 : </p><br>
        <clothesOperationList v-if="true" :clothesId="clothes.clothesId"/>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import clothesOperationList from "@/components/clothes/clothes-operation-list";
export default {
  name: "operation-add",
  created() {
    this.pageInit()
  },
  components:{clothesOperationList},
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    clothes: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      clothesName: this.clothes.styleType+ '-' + this.clothes.styleName+'-' + this.clothes.clothesSize + '-' + this.clothes.clothesNo,
      states: this.state,
      form: {
        clothesId: this.clothes.clothesId,
        createDate: new Date(),
        operationId: "",
        empId: "",
        shopId: "",
        positionId: "",
        remark: "",
        tenantCrop: localStorage.getItem("tenantCrop")
      },
      rules: {
        createDate: [{required: true, message: "请选择操作日期", trigger: "change"}],
        operationId: [{required: true, message: "请选择操作名称", trigger: "change"}],
        shopId: [{required: true, message: "请选择经办人", trigger: "change"}],
        empId: [{required: true, message: "请选择目标店铺", trigger: "change"}],
        positionId: [{required: true, message: "请选择目标位置", trigger: "change"}],
      },
      operationIdArray: [],
      empIdArray: [],
      shopIdArray: [],
      clothesPositionArray: [],
    }
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
    pageInit() {
      this.$selectUtils.queryOperationIds().then(response=>{
        this.operationIdArray=JSON.parse(response.data.data);
      })
      this.$selectUtils.queryEmpIds().then(response=>{
        this.empIdArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.shopIdsIsValid().then(response => {
        this.shopIdArray = JSON.parse(response.data.data);
      })
    },
    queryClothesPosition(shopId) {
      this.form.positionId="";
      this.$selectUtils.queryPositionIdsByShop(shopId).then(response => {
        this.clothesPositionArray = JSON.parse(response.data.data)
      })
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$alert("是否将"+this.clothesName+"移动到"+this.$refs.shop.selectedLabel+ "的"+this.$refs.position.selectedLabel,
            "婚纱操作",
            {
              dangerouslyUseHTMLString: true,
              type: "success",
              showCancelButton: true
            }).then(() => {
                this.$axios({
                  method:"PUT",
                  url:"/clothesOperation/addClothesOperation",
                  params: {
                    ...this.form
                  }
                }).then(response => {
                  let flag = response.data.code === 200
                  this.$message({
                    showClose: true,
                    message: flag ? '操作成功': response.data.msg,
                    type: flag ? 'success':'error',
                    duration: 1000,
                  });
                  if (flag) {
                    this.close();
                  }
                })
            })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>