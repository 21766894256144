<template>
  <el-dialog
      title="婚纱详情"
      width="75%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
  <el-row>
    <el-col :span="10">
      <styleImage :styleInfo="{id:clothesInfo.styleId,styleImage:clothesInfo.styleImage}"  :state="true"/>
    </el-col>
    <el-col :span="13" :offset="1">
      <el-row>
          <el-tag v-for="item in clothesLabel" :key="item.id"
                  type="" effect="dark"
                  style="margin: 0 1%">
            {{ item.labelName }}
          </el-tag>
      </el-row><br>
      <el-form label-width="100px" :model="form" ref="form">
        <el-row>
          <el-form-item label="婚纱名称:" >
            <el-input disabled :value="clothesName"/>
          </el-form-item>
        </el-row><br>
        <el-row>
          <el-form-item label="款式标签 * " >
              <el-select v-model="form.styleLabels"  multiple placeholder="选择标签" clearable>
                <el-option
                    v-for="item in styleLabelIdArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
          </el-form-item>
        </el-row><br>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="款式名称:" >
              <el-input disabled :value="details.styleAlias"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="款式品牌:" >
              <el-input disabled :value="details.brand"/>
            </el-form-item>
          </el-col>
        </el-row><br>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="款式类型:" >
              <el-input disabled :value="details.styleType"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="款式编号:" >
              <el-input disabled :value="details.styleName"/>
            </el-form-item>
          </el-col>
        </el-row><br>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="婚纱尺寸 * " prop="clothesSize">
              <el-select v-model="form.clothesSize" placeholder="婚纱尺寸" clearable class="searchParam">
                <el-option
                    v-for="item in sizeArray"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="婚纱编号">
              <el-input disabled  v-model="details.clothesNo"/>
            </el-form-item>
          </el-col>
        </el-row><br>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="所在店铺:" >
              <el-input disabled :value="details.shopName"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所在区域:" >
              <el-input disabled :value="details.positionName"/>
            </el-form-item>
          </el-col>
        </el-row><br>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="试纱次数:" >
              <el-input disabled :value="details.yarnCount"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出件次数:" >
              <el-input disabled :value="details.outCount"/>
            </el-form-item>
          </el-col>
        </el-row><br>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="采购日期:" >
              <el-input disabled :value="details.purchaseDate"/>
            </el-form-item>
          </el-col>
<!--          <el-col :span="12">-->
<!--            <el-form-item label="采购来源:" >-->
<!--              <el-input readonly :value="details.factoryName"/>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

        </el-row><br>
        <el-row>
          <el-col :span="22">
            <el-form-item label="款式介绍" prop="styleInfo">
              <el-input type="textarea" v-model="form.styleInfo"></el-input>
            </el-form-item>
          </el-col>
        </el-row><br>
        <el-row>
          <el-col :span="22">
            <el-form-item label="适合身形" prop="styleFit">
              <el-input  type="textarea" v-model="form.styleFit"></el-input>
            </el-form-item>
          </el-col>
        </el-row><br>
        <el-row>
          <el-col :span="22">
            <el-form-item label="不适合身形" prop="styleNoFit">
              <el-input type="textarea" v-model="form.styleNoFit"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row style="margin-top: 5vh;text-align: center">
          <el-button type="warning" @click="updateClothes">确认修改</el-button>

      </el-row>
    </el-col>
<!--    <el-col :span="6" :offset="1" style="padding-top: 15px">-->
<!--      <el-button type="warning" @click="operationState = true">位置操作</el-button>-->
<!--      <el-button type="success" @click="operationPageState = true">操作记录</el-button>-->
<!--      <el-button type="primary" @click="schedulePageState = true">婚纱档期</el-button>-->
<!--    </el-col>-->
  </el-row>
    <clothesScheduleDetails v-if="schedulePageState" :clothesId="clothesInfo.clothesId"  :state="schedulePageState" @close="schedulePageState=false"/>
    <operation-add v-if="operationState" :state="operationState" :clothes="details" @close="operationClose"></operation-add>
    <el-drawer
        title="婚纱足迹记录"
        :visible.sync="operationPageState"
        direction="rtl"
        :modal="false"
        @before-close="operationPageState=false">
      <clothesOperationList v-if="operationPageState" :clothesId="clothesInfo.clothesId"  :state="operationPageState" @close="operationPageState=false"/>
    </el-drawer>
  </el-dialog>
</template>

<script>
import clothesScheduleDetails from "@/components/clothes/clothes-schedule-details"
import operationAdd from "@/pages/clothes/operation/operation-add.vue"
import clothesOperationList from "@/components/clothes/clothes-operation-list";
import styleImage from "@/pages/style/style-image";
export default {
  name: "clothes-detail",
  data() {
    return {
      form:{
        id:"",
        styleId:"",
        clothesSize: "",
        styleLabels:"",
        styleInfo:"",
        styleFit:"",
        styleNoFit:"",
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      details:{},
      clothesName:"",
      clothesLabel: [],
      dialogState: this.state,
      schedulePageState: false,
      operationState: false,
      operationPageState: false,
      count: {
        yarnCount: 0,
        outCount: 0,
      },
      sizeArray: [],
      styleLabelIdArray:[],
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    clothesInfo: {
      type: Object,
      required: true,
    }
  },
  components: {
    clothesScheduleDetails,
    operationAdd,
    clothesOperationList,
    styleImage
  },
  created() {
    this.queryClothesDetails();
    this.queryStyleLabel();
    this.queryCount()
    this.queryClothesSize()
    this.queryLabelIdsArr()
  },
  methods: {
    //查询婚纱详情
    queryClothesDetails(){
      this.$axios({
        method:"GET",
        url: "/clothes/queryClothesVo",
        params: {
          id: this.clothesInfo.clothesId
        }
      }).then(response=>{
        this.details=response.data.data
        console.log(this.details)
        this.clothesName=this.details.styleType+ '-' + this.details.styleName+'-' + this.details.clothesSize + '-' + this.details.clothesNo

        this.form.clothesSize=this.details.clothesSize;
        this.form.styleLabels=this.details.styleLabels===""?"":this.details.styleLabels.split(",").map(Number);
        this.form.id=this.details.clothesId;
        this.form.styleId=this.details.styleId;
        this.form.styleInfo=this.details.styleInfo;
        this.form.styleFit=this.details.styleFit;
        this.form.styleNoFit=this.details.styleNoFit;
      })
    },
    //查询款式标签
    queryStyleLabel(){
      this.$axios({
        method:"GET",
        url: "/styleLabel/queryStyleLabelVoByClothesId",
        params: {
          clothesId: this.clothesInfo.clothesId
        }
      }).then(response=>{
        this.clothesLabel=response.data.data
      })
    },
    close(){
      this.$emit("close");
    },
    operationClose() {
      this.operationState = false
      this.queryClothesDetails()
    },
    queryCount() {
      this.$axios({
        method: "get",
        url: "/clothes/queryYarnCountAndOutCountByClothesId",
        params: {
          clothesId: this.clothesInfo.clothesId,
          tenantCrop: localStorage.getItem("tenantCrop"),
        }
      }).then(response => {
        this.count = response.data.data
      })
    },
    updateClothes() {
      console.log(this.form)
      this.$confirm("是否确认修改此婚纱? ", '修改婚纱:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/clothes/updateClothesStyle",
          data: this.form
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("修改成功!")
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 查询尺寸
    queryClothesSize() {
      this.$selectUtils.queryClothesSizeIds().then(response => {
        this.sizeArray = JSON.parse(response.data.data)
      })
    },
    //查询标签类型
    queryLabelIdsArr(){
      this.$selectUtils.queryLabelIds(this.$labelType.style).then(response=>{
        this.styleLabelIdArray=response.data.data;
      })
    },
  }
}
</script>

<style scoped>
/*/deep/ .el-carousel__item.is-active.is-animating{*/
/*  text-align: center;*/
/*}*/
/*/deep/ .el-dialog.el-dialog--center{*/
/*  height: 90%;*/
/*}*/
.details{
  width: 70%;
}
.el-form-item{
  margin-bottom : revert;
}
.el-select{
  width: 100%;
}
</style>